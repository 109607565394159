import React, { useState } from "react";
import { IconButton, Menu, MenuItem, Fade, useMediaQuery } from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";
import { useTheme } from "@emotion/react";

const ResponseTranslationDropdown = ({ setUserLang, userLang }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const isSmallDown = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const languages = [
    { value: "English", label: "English" },
    { value: "Hindi", label: "हिंदी" }, // Hindi
    { value: "Bengali", label: "বাংলা" }, // Bengali
    { value: "Telugu", label: "తెలుగు" }, // Telugu
    { value: "Marathi", label: "मराठी" }, // Marathi
    { value: "Tamil", label: "தமிழ்" }, // Tamil
    { value: "Urdu", label: "اردو" }, // Urdu
    { value: "Gujarati", label: "ગુજરાતી" }, // Gujarati
    { value: "Malayalam", label: "മലയാളം" }, // Malayalam
    { value: "Kannada", label: "ಕನ್ನಡ" }, // Kannada
    { value: "Punjabi", label: "ਪੰਜਾਬੀ (ਗੁਰਮੁਖੀ)" }, // Punjabi (Gurmukhi)
    { value: "Assamese", label: "অসমীয়া" }, // Assamese
    { value: "Odia", label: "ଓଡ଼ିଆ" }, // Odia
    { value: "Sanskrit", label: "संस्कृतम्" }, // Sanskrit
    { value: "Spanish", label: "Español" }, // Spanish
    { value: "French", label: "Français" }, // French
    { value: "German", label: "Deutsch" }, // German
    { value: "Chinese", label: "中文" }, // Chinese
    { value: "Arabic", label: "العربية" }, // Arabic
    { value: "Portuguese", label: "Português" }, // Portuguese
    { value: "Russian", label: "Русский" }, // Russian
    { value: "Japanese", label: "日本語" }, // Japanese
    { value: "Korean", label: "한국어" }, // Korean
    { value: "Italian", label: "Italiano" }, // Italian
    { value: "Dutch", label: "Nederlands" }, // Dutch
    { value: "Swedish", label: "Svenska" }, // Swedish
    { value: "Turkish", label: "Türkçe" }, // Turkish
    { value: "Greek", label: "Ελληνικά" }, // Greek
    { value: "Hebrew", label: "עברית" }, // Hebrew
    { value: "Polish", label: "Polski" }, // Polish
    { value: "Ukrainian", label: "Українська" }, // Ukrainian
    { value: "Vietnamese", label: "Tiếng Việt" }, // Vietnamese
    { value: "Thai", label: "ไทย" }, // Thai
    { value: "Indonesian", label: "Bahasa Indonesia" }, // Indonesian
    { value: "Malay", label: "Bahasa Melayu" }, // Malay
  ];

  return (
    <div>
      <IconButton
        onClick={handleClick}
        style={{
          width: isSmallDown ? "24px" : "30px",
          height: isSmallDown ? "24px" : "30px",
          borderRadius: "15px",
          backgroundColor: "white",
          padding: isSmallDown ? "5px" : "8px",
          color: "#2f2f2f",
          fontSize: "12px",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {userLang.slice(0, 3).toUpperCase()}
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{
          "& .MuiMenu-paper": {
            backgroundColor: "#f0f0f0",
            borderRadius: "8px",
            boxShadow: "0 3px 10px rgba(0,0,0,0.2)",
            maxHeight: "200px",
            overflowY: "auto",
            transition: "transform 0.3s ease, opacity 0.3s ease",
            transform: "translateY(-10px)",
            opacity: 0,
            display: "block",
          },
          "&.MuiPopover-open .MuiMenu-paper": {
            transform: "translateY(0)",
            opacity: 1,
          },
        }}
      >
        {languages.map((language) => (
          <MenuItem
            key={language.value}
            onClick={() => {
              setUserLang(language.value);
              // setAnchorEl(null);
            }}
            sx={{
              padding: "4px 16px", // Adjust padding to reduce height
              "&:hover": {
                backgroundColor: "grey", // Hover effect background color
                color: "#000", // Text color on hover
              },
              color: userLang === language.value ? "#000" : "#333", // Default text color
              height: "32px",
              backgroundColor: userLang === language.value ? "grey" : "inherit",
            }}
          >
            {language.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ResponseTranslationDropdown;
