const sampleQuestions = [
  "Does style code #AG19 run true to size?",
  "Is style code #RX71 available in size XL?",
  "What’s the fit for style code #MV24?",
  "Is style code #AG54 available in small?",
  "Can I get style code #IC30 in a slim fit?",
  "Is style code #8035 available in plus sizes?",
  "Does style code #AP27 have a relaxed fit?",
  "Is style code #AW89 available in oversized?",
  "What are the size measurements for style code #T445?",
  "What’s the newest style code for men's T-shirts?",
  "What new arrivals do you have in men's innerwear?",
  "Show me the newest style codes for sports bras.",
  "What are the latest designs in men's loungewear?",
  "Any new arrivals in women's underwear?",
  "How should I wash style code #AG72?",
  "What are the care instructions for style code #AM71?",
  "Can I machine wash style code #CB01?",
  "Does style code #US59 need to be air dried?",
  "Can I tumble dry style code #MV55?",
  "Does style code #UM36 need to be hand washed?",
  "Is style code #FP03 dry-clean only?",
  "Can I iron style code #NY16?",
  "What’s the washing temperature for style code #MK03?",
  "What’s a good product for women's sports bras?",
  "Suggest a comfortable everyday T-shirt for men.",
  "What’s a good style code for high-impact activities?",
  "What’s the best product for women’s leggings?",
  "Which style code is best for running?",
  "What’s a good recommendation for a gift in men’s underwear?",
  "What’s the most durable style code for men’s socks?",
  "What’s the best Jockey product for hot weather?",
  "Is there a warranty for style code #AM75?",
  "What’s the return policy for style code #3913?",
  "Can I return style code #SG11 if it doesn’t fit?",
  "What’s the warranty period for style code #RX12?",
  "Is style code #RX57 eligible for a refund?",
  "What’s the return window for style code #MW31?",
  "Is style code #7396 covered under warranty?",
  "What is the contact information for customer Dynamic Fashions?",
  "When was customer Mahalakshmi Traders added to our system?",
  "What is the full address and city of customer Pratham Designs Traders?",
  "How many customers are based in Ajmer?",
  "What is the email address for customer Dynamic Fashions?",
  "What is the phone number for customer Sitara Couture Traders?",
  "In which city is customer Ananya Silks Fashions located?",
  "What is the registration date for customer Sargun Design Studio Traders?",
  "List all customers from Palakkad.",
  "What is the current status of order 127?",
  "What is the total amount for order 532?",
  "On what date was order 859 placed?",
  "List all orders placed by customer Sanskruti Trends Traders.",
  "How many orders are currently in 'Pending' status?",
  "List all orders sorted by order amount",
  "Which orders were delivered within this month?",
  "Which orders were placed on 5th November 2024?",
  "How many orders are in 'Shipped' status for customer Kaaya Ethnic Fashions?",
  "How many total orders were placed this month?",
  "How many units of product MW72 are available in stock?",
  "Which products have less than 150 units in stock?",
  "What is the total stock quantity for warehouse 5?",
  "How many products are currently out of stock?",
  "What are the top 5 products by stock quantity?",
  "List all products with stock below 140.",
  "Which products had inventory moved in or out on 19th October 2024?",
  "How many invoices have a 'Pending' status?",
  "What is the total amount of unpaid invoices?",
  "Which customers have overdue invoices?",
  "Who are the top 5 customers by total invoice amount?",
];
module.exports = sampleQuestions;
