import { useState } from "react";
import { Container } from "@mui/material";
import MessageContainer from "./container/messageContainer";
import MessageInput from "./input/messageInput";
import Navbar from "./navbar/navbar";
import { v4 as uuidv4 } from "uuid";

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [feedback, setFeedback] = useState(0);
  const [sessionId, setSessionId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userLang, setUserLang] = useState("English");

  const translateText = async (text, sourceLang, targetLang) => {
    try {
      const translateApiUrl = process.env.REACT_APP_TRANSLATE_API;
      console.log(
        `Translating text from ${sourceLang} to ${targetLang} via ${translateApiUrl}`
      );

      const response = await fetch(translateApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          text: text,
          sourceLang: sourceLang,
          targetLang: targetLang,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Failed to translate text:", errorText);
        throw new Error("Failed to translate text");
      }

      const data = await response.json();
      return data.translatedText;
    } catch (error) {
      console.error("Error translating text:", error);
      throw error;
    }
  };
  const handleSendMessage = async (message, handleCategory) => {
    const senderMessage = {
      sender: "user",
      senderID: "test1",
      senderType: "admin",
      text: message,
    };
    setMessages((prevMessages) => [...prevMessages, senderMessage]);
    setIsLoading(true);
    // let updatedMessages = messages;
    // updatedMessages.push(senderMessage);
    // setMessages(updatedMessages);
    try {
      // Step 1: Translate the user's message to English
      let translatedQuery = message;
      // if (userLang !== "English") {
      //   translatedQuery = await translateText(message, userLang, "English");
      //   console.log("Translated query to English:", translatedQuery);
      // }
      translatedQuery = await translateText(message, userLang, "English");
      console.log("Translated query to English:", translatedQuery);
      // let summary = "";
      // if (updatedMessages.length === 0) {
      //   summary = "";
      // } else {
      //   // summary = await summarizeMessages();
      // }

      const combinedMessage = `${translatedQuery}`;
      const apiUrl = process.env.REACT_APP_CHAT_API;
      console.log(`Sending chat request to ${apiUrl}`);
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: combinedMessage,
          sessionId: sessionId === "" ? null : sessionId,
          summary: "",
        }),
      });

      setIsLoading(false);
      if (!response.ok) {
        const errorText = await response.text();
        console.error("Network response was not ok:", errorText);
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Chat API data received:", data);

      const chatResponse = data.generated_text;
     
        const formattedResponse = chatResponse.startsWith('"')
          ? JSON.parse(chatResponse)
          : chatResponse;

        let translatedResponse = formattedResponse;
        if (userLang !== "English") {
          translatedResponse = await translateText(
            formattedResponse,
            "English",
            userLang
          );
          console.log(
            "Translated bot response to user language:",
            translatedResponse
          );
        }

        const botMessage = {
          sender: "bot",
          id: uuidv4(),
          text: translatedResponse,
          productDetails: data.productDetails,
        };

        setMessages((prevMessages) => [...prevMessages, botMessage]);
        await saveChatSession(senderMessage, botMessage, feedback);
      
    } catch (error) {
      console.error("Error handling message:", error);
      setIsLoading(false);
    }
  };

  const saveChatSession = async (senderMessage, botMessage, feedback) => {
    try {
      const apiUrlSaveSession = process.env.REACT_APP_SAVE_CHAT_API;
      console.log(`Saving chat session to ${apiUrlSaveSession}`);

      const response = await fetch(apiUrlSaveSession, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: sessionId,
          userMessage: senderMessage,
          botResponse: botMessage,
          responseFeedback: feedback,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error saving chat session:", errorText);
        throw new Error("Error saving chat session");
      }

      const data = await response.json();
      console.log("Chat session saved successfully:", data);
      setSessionId(data.id);
    } catch (error) {
      console.error("Error saving chat session:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: "Error generating response" },
      ]);
    }
  };


  
  return (
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 2,
        height: "100vh",
      }}
    >
      <Navbar />
      <MessageContainer
        messages={messages}
        sessionId={sessionId}
        feedback={feedback}
        onFeedbackChange={setFeedback}
        handleSend={handleSendMessage}
        loading={isLoading}
      />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position:"sticky",
           bottom: "0",

        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <MessageInput
            handleSend={handleSendMessage}
            userLang={userLang}
            setUserLang={setUserLang}
            isLoading={isLoading}

          />
        </div>
      </div>
    </Container>
  );
};

export default Chat;
