import { Box, Container, Link, Typography, useMediaQuery } from "@mui/material";

import React, { useEffect, useRef, useState } from "react";
import Logo from "../../assets/Logo.png";
import { useTheme } from "@emotion/react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import IconButton from "@mui/material/IconButton";
import thumbsUpIcon from "../../assets/thumbsUpIcon.svg";
import thumbsUpIconFilled from "../../assets/thumbsUpIconFilled.svg";
import thumbsDownIcon from "../../assets/thumbsDownIcon.svg";
import thumbsDownIconFilled from "../../assets/thumbsDownIconFilled.svg";
import logo from "../../assets/Logo.svg";
import PulseLoader from "react-spinners/PulseLoader";

const MarkdownImage = ({ src, alt }) => {
  const [imageLoaded, setImageLoaded] = useState(true);
  return imageLoaded ? (
    <img
      src={src}
      alt={alt}
      style={{
        width: "200px",
        height: "240px",
        display: "block",
        margin: "10px 0",
      }}
      onError={() => setImageLoaded(false)}
    />
  ) : null;
};
const MarkdownLink = ({ href, children }) => (
  <Link
    href={href}
    onError={(e) => (e.target.style.display = "none")}
    target="_blank"
    rel="noopener noreferrer"
    color="#7ab7ff"
  >
    {children}
  </Link>
);
const renderers = {
  img: MarkdownImage,
  a: MarkdownLink,
};

function MessageContainer({
  messages,
  sessionId,
  handleSend,
  loading,
}) {
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  const [feedback, setFeedback] = useState({});
  const lastMessageRef = useRef(null);
  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleFeedback = async (index, feedbackType) => {
    setFeedback((prevFeedback) => {
      const updatedFeedback = {
        ...prevFeedback,
        [index]: feedbackType === prevFeedback[index] ? 0 : feedbackType,
      };
      updateFeedbackInDatabase({
        sessionId,
        messageId: messages[index].id,
        responseFeedback: updatedFeedback[index],
      });
      return updatedFeedback;
    });
  };

  const updateFeedbackInDatabase = async (feedbackData) => {
    try {
      const response = await fetch(process.env.REACT_APP_UPDATE_FEEDBACK_API, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(feedbackData),
      });

      if (!response.ok) {
        throw new Error("Failed to update feedback");
      }

      const result = await response.json();
      console.log("Feedback updated successfully:", result);
    } catch (error) {
      console.error("Error updating feedback:", error);
    }
  };

  const sampleQuestions = require("./sampleQuestions");

  const getRandomQuestion = () => {
    let remainingQuestions;
    remainingQuestions = [...sampleQuestions];

    const randomIndex = Math.floor(Math.random() * remainingQuestions?.length);
    const question = remainingQuestions[randomIndex];
    remainingQuestions.splice(randomIndex, 1);
    return question;
  };

  const handleRandomQuestion =()=>{
    const randomQuestion = getRandomQuestion();
    handleSend(randomQuestion);
  }

  return (
    <Container
      sx={{
        flexGrow: 1,
        width: isMediumDown ? "100%" : "80%",
        display: "flex",
        flexDirection: "column",
        maxWidth: isMediumDown ? "100%" : "80%",
      }}
    >
      {messages.length !== 0 ? (
        <>
          <div
            style={{
              display: "flex",
              gap: "10px",
              paddingBottom: "30px",
              justifyContent: "center",
              position: "sticky",
              top: "90px",
              zIndex: theme.zIndex.drawer + 1,
              backgroundColor: "#212121",
              alignItems: "center",
            }}
          >
            <div
              style={{
                border: "1px solid rgb(155 155 155)",
                borderRadius: "4px",
                padding: "10px",
                color: "rgb(155 155 155)",
                fontSize: isMediumDown ? "12px" : "16px",
                cursor: "pointer",
              }}
              onClick={handleRandomQuestion}
            >
              Check out our sample questions!!{" "}
            </div>
          </div>
          <div style={{ paddingTop: "90px" }}>
            {messages.map((message, index) => (
              <Box
                key={index}
                sx={{
                  marginBottom: "25px",
                }}
                ref={index === messages.length - 1 ? lastMessageRef : null}
              >
                <div
                  style={{
                    alignItems: "flex-start",
                    display: "flex",
                    justifyContent:
                      message.sender !== "user" ? "flex-start" : "flex-end",
                    gap: "15px",
                  }}
                >
                  {message.sender !== "user" && (
                    <Box>
                      <img src={Logo} alt="Jockey Logo" width="20px" />
                    </Box>
                  )}

                  <Typography
                    variant="body1"
                    sx={{
                      display: "inline-block",
                      padding: message.sender === "user" ? "6px 11px" : 0,
                      borderRadius: "20px",
                      backgroundColor:
                        message.sender === "user" ? "#2f2f2f" : "",
                      color: "rgb(244 244 244)",
                      maxWidth: isMediumDown ? undefined : "80%",
                      lineHeight:
                        message.sender === "user" ? "1.2rem" : "1.5rem",
                      boxSizing: "border-box",
                      margin: 0,
                      fontSize: isMediumDown ? "14px" : "1rem",
                    }}
                  >
                    <ReactMarkdown
                      children={message.text}
                      components={{
                        ...renderers,
                        p: ({ node, ...props }) => (
                          <p {...props} style={{ margin: 0 }} />
                        ),
                        ul: ({ node, ...props }) => (
                          <ul
                            {...props}
                            style={{
                              listStyleType: "none",
                              padding: 0,
                              margin: 0,
                            }}
                          />
                        ),
                      }}
                      rehypePlugins={[rehypeRaw]}
                    />
                  </Typography>
                </div>

                {message.sender !== "user" && (
                  <div
                    key={message.id}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginLeft: "35px",
                    }}
                  >
                    <IconButton
                      onClick={() => handleFeedback(index, 1)}
                      size="small"
                    >
                      {feedback[index] === 1 ? (
                        <img src={thumbsUpIconFilled} alt="thumbsUpFilled" />
                      ) : (
                        <img src={thumbsUpIcon} alt="thumbsUp" />
                      )}
                    </IconButton>
                    <IconButton
                      onClick={() => handleFeedback(index, -1)}
                      size="small"
                    >
                      {feedback[index] === -1 ? (
                        <img
                          src={thumbsDownIconFilled}
                          alt="thumbsDownFilled"
                        />
                      ) : (
                        <img src={thumbsDownIcon} alt="thumbsDown" />
                      )}
                    </IconButton>
                  </div>
                )}
              </Box>
            ))}
            {loading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 2,
                }}
              >
                <PulseLoader color="grey" />
              </Box>
            )}
          </div>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100%",
            gap: "5%",
            marginTop: isMediumDown ? "22%" : "5%",
          }}
        >
          <img src={logo} alt="Logo" width={isMediumDown ? "15%" : "6%"} />
          <div
            style={{
              color: "rgb(155 155 155)",
              fontWeight: "bolder",
              fontSize: isMediumDown ? "12px" : "16px",
            }}
          >
            Looking for quick tips or need product details on the go?
          </div>
          <div
            style={{
              border: "1px solid rgb(155 155 155)",
              borderRadius: "4px",
              padding: "10px",
              color: "rgb(155 155 155)",
              fontSize: isMediumDown ? "12px" : "16px",
              cursor: "pointer",
            }}
            onClick={handleRandomQuestion}
          >
            Check out our sample questions!!{" "}
          </div>
        </Box>
      )}
    </Container>
  );
}

export default MessageContainer;
